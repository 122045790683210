<template>
  <div id="result-component">
    <div v-if="!isEmpty(getSearchFlightArray)">
      <b-tabs
        v-model="tabIndex"
        pills
        nav-class="tab-title"
        align="center"
        nav-wrapper-class="nav-wrapper-class"
      >
        <!-- nav-wrapper-class="nav-wrapper-class border-info" -->
        <b-tab
          v-for="(itinerary, itineraryIndex) in getSearchFlightArray"
          :key="itineraryIndex"
        >
          <template #title>
            <div class="d-flex-column">
              <div
                :class="`d-flex text_info_class_booking fw-700 mb-50 text-nowrap
                ${['xs','sm','md'].includes(appBreakPoint) ? 'font-medium-1' : 'font-medium-3'}`"
              >
                #{{ itineraryIndex + 1 }} {{ itinerary.startPoint }}-{{ itinerary.endPoint }} {{ convertISODateTime(itinerary.departDate).dayAndMonth }}
              </div>
              <div
                v-if="!selectedTrips[itineraryIndex] || !selectedTrips[itineraryIndex][0]"
                class="d-flex-center"
              >
                <span class="text-warning font-weight-bolder font-small-4">
                  {{ $t('flight.notSelectedFlights') }}
                </span>
              </div>
              <div
                v-else-if="selectedTrips[itineraryIndex] && selectedTrips[itineraryIndex][0]"
                class="d-flex-center"
              >
                <div class="text-nowrap font-weight-bolder">
                  {{ selectedTrips[itineraryIndex][0].airline }}{{ selectedTrips[itineraryIndex][0].flightNumber }} {{ selectedTrips[itineraryIndex][0].bookingClass.code }} {{ convertISODateTime(selectedTrips[itineraryIndex][0].departureDate, selectedTrips[itineraryIndex][0].departureTimezone).hourMin }} {{ convertISODateTime(selectedTrips[itineraryIndex][0].arrivalDate, selectedTrips[itineraryIndex][0].arrivalTimezone).hourMin }}
                </div>
              </div>
            </div>
          </template>

          <!-- ANCHOR - TRIPS -->
          <template v-if="!refLoading && dataTrips[itineraryIndex] && dataTrips[itineraryIndex].trips && !isEmpty(dataTrips[itineraryIndex].trips)">
            <div class="scroll-customize">
              <b-card
                v-for="(trip, tripIndex) in dataTrips[itineraryIndex].trips"
                :key="tripIndex"
                class="border-info shadow mb-50"
                body-class="px-50 py-25"
              >
                <b-row
                  v-for="(segment, segmentIndex) in trip.segments"
                  :key="segmentIndex"
                  no-gutters
                >
                  <b-col
                    cols="12"
                    class="text-dark d-flex flex-wrap"
                    :class="segmentIndex > 0 ? 'pl-2' : ''"
                  >
                    <div class="d-flex align-items-center fw-700 font-medium-1 mr-75">
                      <b-badge
                        v-if="segmentIndex === 0"
                        variant="info"
                        class="mr-25 fw-700"
                        pill
                      >
                        {{ tripIndex + 1 }}
                      </b-badge>
                      <span class="mr-50 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).time }}-{{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time }}</span>
                      <span class="mr-50 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth }}</span>
                      <span class="mr-50 text-nowrap text-danger">{{ `${segment.airline}${segment.flightNumber}` }}</span>
                      <span class="mr-50 text-nowrap">{{ `${segment.departure}${segment.arrival}` }}</span>
                      <span class="text-nowrap">{{ `| ${segment.airCraft}` }}</span>
                    </div>
                    <b-button
                      v-if="!['VN1A'].includes(airlineSearch)"
                      class="float-right rounded-lg px-50 py-25 my-25 mr-25 ml-0"
                      variant="flat-warning"
                      style="min-width: 80px;"
                      size="sm"
                      @click="handleShowModalInfoTrip(segment)"
                    >
                      <span class="border-bottom-warning">Tình trạng chuyến bay</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    :class="segmentIndex > 0 ? 'pl-2' : ''"
                  >
                    <!-- ANCHOR - 1G group theo groupClass  -->
                    <template v-if="['1G'].includes(airlineSearch)">
                      <div
                        v-for="(values, key, groupClassIndex) in groupBy(segment.bookingClassAvail, 'groupClass')"
                        :key="groupClassIndex"
                        class="mb-25 ml-50"
                      >
                        <div class="d-flex align-items-center">
                          <div :class="`${key === 'Business' ? 'text-success' : ''} mr-50 fw-700`">
                            {{ key.slice(0,3) }}
                          </div>
                          <div>
                            <b-button
                              v-for="(bookingClass, bookingClassIndex) in values"
                              :id="`btn-booking-class-${bookingClass.uniqueBookingClassId}`"
                              :key="bookingClassIndex"
                              variant="transparent"
                              :class="`
                              btn_booking_class mr-25 mb-25 px-25 py-0
                              ${resolveClassColor(bookingClass)}
                              `"
                              @click="handleSelectClassBooking(segment, bookingClass)"
                            >
                              <div>
                                <span :class="`fw-700`">{{ bookingClass.code }}</span> <span>({{ bookingClass.availability }})</span>
                              </div>
                              <b-tooltip
                                v-if="appBreakPoint !== 'xs'"
                                :id="`tooltip-booking-class-${bookingClass.uniqueBookingClassId}`"
                                :target="`btn-booking-class-${bookingClass.uniqueBookingClassId}`"
                                triggers="hover"
                                custom-class="tooltip-index-2"
                                placement="top"
                                boundary="window"
                                :variant="resolveTooltipVariant(bookingClass)"
                              >
                                <div class="d-flex-center text-nowrap">
                                  <div
                                    v-if="bookingClass.prices[0].fareBasisCode"
                                    class="text-white mr-1 font-small-4"
                                  >
                                    {{ bookingClass.prices[0].fareBasisCode }}
                                  </div>
                                </div>
                                <div :class="`font-italic text-white font-small-4`">
                                  ({{ $t('flight.fareNotIncludeServiceFee') }})
                                </div>
                              </b-tooltip>
                            </b-button>
                          </div>
                        </div>
                      </div>
                      <i class="text-danger font-weight-bolder text-uppercase text-nowrap font-small-3">{{ segment.operating ? getAirlineNameByCode(segment.operating) : '' }}</i>
                    </template>

                    <!-- ANCHOR - VU  -->
                    <template v-else-if="['VU'].includes(airlineSearch)">
                      <b-button
                        v-for="(bookingClass, index) in segment.bookingClassAvail"
                        :id="`btn-booking-class-${bookingClass.uniqueBookingClassId}`"
                        :key="index"
                        variant="transparent"
                        :class="`
                          btn_booking_class mr-25 mb-25 px-25 py-0
                          ${resolveClassColor(bookingClass)}
                          `"
                        @click="handleSelectClassBooking(segment, bookingClass)"
                      >
                        <!-- NOTE: Show hạng vé VU -->
                        <div>
                          <span :class="`fw-700 ${bookingClass.refundable ? '' : 'text-danger'}`">{{ bookingClass.code }}</span><span>({{ bookingClass.availability }})</span>
                          <span
                            v-if="indexBookingClassShowPrice === bookingClass.uniqueBookingClassId || bookingClass.firstPriceShow || listSelectedClassId.includes(bookingClass.uniqueBookingClassId)"
                            class="fw-700"
                          >
                            {{ `= ${resolvePriceShow(bookingClass)}` }}
                          </span>
                        </div>
                        <b-tooltip
                          v-if="appBreakPoint !== 'xs'"
                          :id="`tooltip-booking-class-${bookingClass.uniqueBookingClassId}`"
                          :target="`btn-booking-class-${bookingClass.uniqueBookingClassId}`"
                          triggers="hover"
                          custom-class="tooltip-index-2"
                          placement="top"
                          boundary="window"
                          :variant="resolveTooltipVariant(bookingClass)"
                        >
                          <div class="d-flex-center text-nowrap">
                            <div
                              v-if="bookingClass.prices[0].fareBasisCode"
                              class="text-white mr-1 font-small-4"
                            >
                              {{ bookingClass.prices[0].fareBasisCode }}
                            </div>
                            <div
                              v-if="['VU'].includes(bookingClass.source)"
                              :class="`fw-800 font-small-4 ${bookingClass.refundable ? 'text-white' : 'text-danger'}`"
                            >
                              {{ bookingClass.refundable ? 'Được phép hoàn' : 'Không được hoàn' }}
                            </div>
                          </div>
                          <div :class="`font-italic text-white font-small-4`">
                            ({{ $t('flight.fareNotIncludeServiceFee') }})
                          </div>
                        </b-tooltip>
                      </b-button>
                      <i class="text-danger font-weight-bolder text-uppercase text-nowrap font-small-3">{{ segment.operating ? getAirlineNameByCode(segment.operating) : '' }}</i>
                    </template>

                    <!-- ANCHOR - VN1A  -->
                    <template v-else-if="['VN1A'].includes(airlineSearch)">
                      <b-button
                        v-for="(bookingClass, index) in segment.bookingClassAvail"
                        :id="`btn-booking-class-${bookingClass.uniqueBookingClassId}`"
                        :key="index"
                        variant="transparent"
                        :class="`
                          btn_booking_class mr-25 mb-25 px-25 py-0
                          ${resolveClassColor(bookingClass)}
                          `"
                        @click="handleSelectClassBooking(segment, bookingClass)"
                      >
                        <!-- NOTE: Show hạng vé VU -->
                        <div>
                          <span class="fw-700">{{ bookingClass.code }}</span><span>({{ bookingClass.availability }})</span>
                        </div>

                        <b-tooltip
                          v-if="appBreakPoint !== 'xs' && ['DS', 'LL'].includes(bookingClass?.status)"
                          :id="`tooltip-booking-class-${bookingClass.uniqueBookingClassId}`"
                          :target="`btn-booking-class-${bookingClass.uniqueBookingClassId}`"
                          triggers="hover"
                          custom-class="tooltip-index-2"
                          placement="top"
                          boundary="window"
                          :variant="resolveTooltipVariant(bookingClass)"
                        >
                          <div class="d-flex-center text-nowrap">
                            Đặt sổ chờ: {{ bookingClass.status }}
                          </div>
                        </b-tooltip>
                      </b-button>
                      <b-badge
                        v-if="isEmpty(segment.bookingClassAvail)"
                        variant="danger"
                        class="fw-700 pt-50 pb-25"
                      >
                        Hành trình hết chỗ
                      </b-badge>

                      <i class="text-danger font-weight-bolder text-uppercase text-nowrap font-small-3">{{ segment.operating ? getAirlineNameByCode(segment.operating) : '' }}</i>
                    </template>

                    <template v-else>
                      <div class="fw-700 text-danger">
                        Hệ thống đặt vé: {{ airlineSearch }}
                      </div>
                    </template>
                  </b-col>
                  <hr
                    v-if="(trip.segments.length > 1) && (segmentIndex === 0)"
                    class="mx-3 mx-md-5 w-100 mb-0 mt-25"
                  >
                </b-row>
              </b-card>
            </div>

            <div
              v-if="isLoadingSearchMore"
              class="mt-75 fw-700 text-warning d-flex-center flex-column flex-lg-row"
            >
              <b-spinner
                small
                variant="warning"
                class="mr-lg-1"
              />
              <div>
                {{ $t("flight['Loading more flights']") }}
              </div>
            </div>

            <!-- ANCHOR - BUTTON tính giá, tải thêm, làm mới, goToTop -->
            <div class="sticky_refresh d-flex-center">
              <div
                v-if="['1G', 'VN1A'].includes(airlineSearch)"
                class="sticky_refresh show mr-50 mr-md-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  class="d-flex-center py-50 px-75"
                  pill
                  @click="handleOpenCalculatePriceModal('result')"
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    size="18"
                    class="mr-25"
                  />
                  {{ $t('reservation.recalculatePrice.title') }}
                </b-button>
              </div>

              <div
                v-if="['1G'].includes(airlineSearch)"
                class="sticky_refresh show mr-50 mr-md-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  class="d-flex-center py-50 px-1"
                  pill
                  :disabled="!getNextResultReference"
                  @click="handleSearchMoreResult"
                >
                  <feather-icon
                    icon="ChevronsDownIcon"
                    size="18"
                    class="mr-25"
                  />
                  {{ $t('flight.loadMore') }}
                </b-button>
              </div>

              <b-button
                variant="warning"
                class="px-1 py-50 mr-50 mr-md-1 rounded-pill d-flex-center"
                @click="handleRefreshResultSearch"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="18"
                  :class="`${isMobileView ? '' : 'mr-25'}`"
                />
                {{ isMobileView ? '' : 'Làm mới' }}
              </b-button>

              <div class="sticky_refresh show">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  :class="`d-flex-center py-50 px-1 ${['1G'].includes(airlineSearch) ? 'mr-50 mr-md-1' : ''}`"
                  pill
                  @click="() => $emit('scrollToTarget')"
                >
                  <feather-icon
                    icon="ArrowDownIcon"
                    size="18"
                  />
                </b-button>
              </div>
            </div>
          </template>

          <b-alert
            v-else-if="!refLoading && dataTrips[itineraryIndex] && dataTrips[itineraryIndex].trips && isEmpty(dataTrips[itineraryIndex].trips)"
            variant="danger"
            show
            class="text-center px-50 px-lg-0 py-75 py-lg-1 my-2 font-weight-bolder font-medium-2"
          >
            <feather-icon
              icon="InfoIcon"
              size="21"
              class="text-danger mr-25"
              style="margin-bottom: 3px"
            />
            Không có chuyến bay được khai thác trên hành trình này
          </b-alert>

          <div
            v-if="refLoading"
            class="d-flex-center flex-column"
            style="height: 80px;"
          >
            <b-spinner
              variant="info"
              class="mt-1 mb-50"
            />

            <div class="text-airline font-weight-bolder">
              Hệ thống đang tìm kiếm chuyến bay, vui lòng đợi...
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <b-card
      v-else
      class="text-center d-flex-center"
      style="min-height: 200px"
      no-body
    >
      <span>
        Không tìm thấy thông tin chuyến bay, vui lòng tải lại trang và thực hiện lại
      </span>
    </b-card>

    <ModalInfoTrip :segment-info="segmentInfo" />

    <ModalCalcPriceClassBooking
      :modal-id="'result'"
      @scrollToCardShowPrice="scrollToCardShowPrice"
    />
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton, BTooltip,
  BTabs, BTab, BAlert, BSpinner,
  BBadge,
} from 'bootstrap-vue'
import {
  ref,
  watch,
  // onBeforeMount, onUnmounted, computed,
} from '@vue/composition-api'

import {
  formatCurrency,
  convertISODateTime,
  convertISODateTimeLangVN,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'
import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BTooltip,
    BTabs,
    BTab,
    BAlert,
    BSpinner,
    BBadge,

    ModalInfoTrip: () => import('@flightv2/ModalInfoTrip.vue'),
    ModalCalcPriceClassBooking: () => import('@flightv2/result-class-booking/components/ModalCalcPriceClassBooking.vue'),
  },
  setup(_, { emit }) {
    const {
      getSearchFlightArray,
      getClassBookingInfoTrip,
      searchClassBooking,
      searchMoreResultClassBooking,
    } = useBookingHandle()
    const {
      isEmpty,
      groupBy,
      toastError,
      toastSuccess,
      toastWarning,
      getAirlineNameByCode,
      resolveTooltipVariant,

      showTotalPriceADT,
      indexBookingClassShowPrice,
      refLoading,
      dataTrips,
      selectedTrips,
      listSelectedClassId,
      airlineSearch,
      handleSelectClassBooking,
      handleOpenCalculatePriceModal,
      getSortTripBySegment,
      getNextResultReference,
    } = useClassBookingHandle()

    const segmentInfo = ref([])

    watch(() => dataTrips.value, () => {
      indexBookingClassShowPrice.value = -1
    }, { deep: true, immediate: true })

    function resolveClassColor(bookingClass) {
      if (['VN1A'].includes(airlineSearch.value)) {
        if (listSelectedClassId.value.includes(bookingClass.uniqueBookingClassId)) {
          return `${bookingClass.status}_seats selected_${bookingClass.status}_seats`
        }
        return `${bookingClass.status}_seats`
      }

      if (listSelectedClassId.value.includes(bookingClass.uniqueBookingClassId)) {
        return bookingClass.availability === 0 ? 'unavailable_seats selected_unavail_seats' : 'available_seats selected_avail_seats'
      }
      return bookingClass.availability === 0 ? 'unavailable_seats' : 'available_seats'
    }

    async function handleShowModalInfoTrip(segment) {
      const payloadSearch = { ...JSON.parse(localStorage.getItem('searchFlight')) }
      segmentInfo.value = []
      this.$bvModal.show('modal-api-loading')
      try {
        const payload = { classBookingId: segment.bookingClassId }
        const tripData = { segmentIndex: segment.leg, legsTrip: segment.legsTrip, airline: segment.airline }

        getClassBookingInfoTrip(payload, payloadSearch, tripData)
          .then(res => {
            segmentInfo.value = res
            this.$bvModal.show('modal-flightv2-show-info-trip')
          })
          .catch(err => {
            toastError({
              title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
            })
            console.error({ err })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
      } catch (error) {
        console.error({ error })
        toastError({
          title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
        })
      }
    }

    function handleRefreshResultSearch() {
      const payloadSearch = { ...JSON.parse(localStorage.getItem('searchFlight')) }
      // Scroll to the top of the page
      window.scrollTo({ top: 141, behavior: 'smooth' })
      searchClassBooking(payloadSearch, 'refresh')
        .then(() => {
          toastSuccess({ title: 'Đã làm mới thông tin hành trình!' })
        })
        .catch(() => {
        //
        })
    }

    const isLoadingSearchMore = ref(false)

    function handleSearchMoreResult() {
      if (!getNextResultReference.value) {
        toastWarning({ title: 'Đã tải tất cả các hành trình !' })
        return
      }

      isLoadingSearchMore.value = true

      searchMoreResultClassBooking()
        .then(res => {
          toastSuccess({ title: 'Đã thêm dữ liệu hành trình!' })
        })
        .finally(() => {
          isLoadingSearchMore.value = false
        })
    }

    function resolvePriceShow(bookingClass) {
      const getTypeShow = showTotalPriceADT.value ? 'fare' : 'net'
      try {
        const price = bookingClass.prices[0][`${getTypeShow}`]
        return price ? formatCurrency(price) : 0
      } catch (error) {
        console.error('Error in resolvePriceShow', { error })
        return 0
      }
    }

    function scrollToCardShowPrice() {
      emit('scrollToCardShowPrice')
    }

    return {
      formatCurrency,
      convertISODateTime,
      convertISODateTimeLangVN,
      resolveTooltipVariant,
      isEmpty,
      groupBy,

      getSearchFlightArray,
      dataTrips,
      segmentInfo,
      airlineSearch,
      listSelectedClassId,
      isLoadingSearchMore,
      getNextResultReference,
      handleShowModalInfoTrip,
      handleSelectClassBooking,
      selectedTrips,
      indexBookingClassShowPrice,
      getAirlineNameByCode,

      resolveClassColor,
      handleRefreshResultSearch,
      handleSearchMoreResult,
      refLoading,

      scrollToCardShowPrice,
      resolvePriceShow,
      getSortTripBySegment,
      handleOpenCalculatePriceModal,
    }
  },
  computed: {
    tabIndex: {
      get() { return this.$store.getters['app-flight-v2/getTabIndex'] },
      set(newValue) {
        this.$store.dispatch('app-flight-v2/setTabIndex', newValue)

        // Scroll to the top of the page
        window.scrollTo({ top: 141, behavior: 'smooth' })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
#result-component ::v-deep {
  .nav-wrapper-class {
    display: block;
    // display: none;
    position: sticky;
    top: 50px;
    z-index: 10;
    background: rgba(#e9f1fc, 0.8);
    backdrop-filter: blur(5px);
    margin: 0 0 2rem 0;
    border-radius: 8px;
    padding: 0;

    .nav-pills {
      margin: 0;
    }
  }

  .nav {
    flex-wrap: nowrap;
    justify-content: start !important;
    overflow-x: auto;
  }

  .tab-title .nav-item  {
    min-width: auto;
    margin: .6rem 0px;
    background: #ffffffc5;
    box-shadow: 0px 4px 6px rgba(0, 12, 43, 0.308);
    border-radius: 8px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;

    .nav-link {
     padding: .8rem 1rem .5rem;

     @media (max-width: 576px) {
       padding: .5rem .8rem .5rem;
      }
    }

    .nav-link.active {
      background: #9bd5eb;
      height: 100%;
      width: 100%;
      color: #166987;
      border: none;
    }
  }
}

::v-deep .btn_booking_class {
  min-width: 30px;
  min-height: 30px;

   .tooltip {
    z-index: 2 !important;
  }
}

.available_seats {
  color: #166987;
  border: 2px solid #166987;

  &.selected_avail_seats {
    background-color: #166987;
    color: white;
  }
}

.unavailable_seats {
  color: #CCCCCC;
  border: 2px solid #CCCCCC;

  &.selected_unavail_seats {
    background-color: #999;
    border: 2px solid #999;
    color: white;
  }
}

.text_info_class_booking {
  color: #166987;
}

.sticky_refresh {
  position: sticky;
  bottom: 0;
  z-index: 19;
  // margin: 0.5rem 0;
  border-radius: 8px;
  padding: .5rem 0 !important;

  @media (max-width: 576px) {
    bottom: 3px;
    }
}

.scroll-customize {
  height: calc(70vh - 83px);
  overflow-y: scroll;
}

/* For Webkit browsers (Chrome, Safari) */
.scroll-customize::-webkit-scrollbar {
  width: 8px; /* Độ rộng của thanh cuộn */
  height: 8px; /* Độ rộng của thanh cuộn ngang nếu có */
}

.scroll-customize::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Màu của thanh cuộn */
  border-radius: 10px; /* Độ bo góc của thanh cuộn */
  border: 2px solid transparent; /* Tạo khoảng cách xung quanh thanh cuộn để thấy rõ góc tròn */
  background-clip: content-box; /* Đảm bảo nền của thanh cuộn không tràn ra ngoài */
}

/* Optional: Adding hover effect for the scrollbar */
.scroll-customize::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4); /* Màu của thanh cuộn khi hover */
}

/* For Firefox */
.scroll-customize {
  scrollbar-width: thin; /* Độ rộng của thanh cuộn */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Màu của thanh cuộn và background của nó */
}

/* Optional: Adding hover effect for the scrollbar in Firefox */
.scroll-customize:hover {
  scrollbar-color: rgba(0, 0, 0, 0.4) transparent;
}

.NN_seats {
  color: #166987;
  border: 2px solid #166987;

  &.selected_NN_seats {
    background-color: #166987;
    color: white;
  }
}

.LL_seats {
  color: #FFC107;
  border: 2px solid #FFC107;

  &.selected_LL_seats {
    background-color: #FFC107;
    border: 2px solid #FFC107;
    color: black;
  }
}

.DS_seats {
  color: #dc3545;
  border: 2px solid #dc3545;

  &.selected_DS_seats {
    background-color: #dc3545;
    border: 2px solid #dc3545;
    color: white;
  }
}
</style>
